<template>
  <div>
    <div class="pad-top-twenty margin-bottom-thirty">
      <div :class="isCarded ? 'scrollable' : ''">
        <ion-row v-if="!isCarded" class="ion-align-items-center">
          <ion-col class="margin-bottom-ten">
            <h3>Diagnosis Considerations</h3>
          </ion-col>
          <ion-col size="auto">
            <ion-spinner
              v-if="isSavingCounter > 0"
              name="lines-small"
              class="loading-overlay center-center"
              color="light"
            ></ion-spinner>
            <button
              v-if="addressedDocRefs?.length"
              class="prime-button button-block display-flex valign"
              @click="copyDocumentation()"
            >
              <IconCopy slot="start" class="icon-small icon-dark-grey margin-right-five" />
              Copy Combined Documentation
            </button>
          </ion-col>
        </ion-row>
        <ion-row class="pad-bottom-twenty">
          <DiagnosisConsiderationWrapper
            class="dx-container"
            :beneficiaryId="beneficiaryId"
            :showSubcategoryTitle="true"
          />
        </ion-row>
      </div>
    </div>
  </div>
</template>

<script>
import IconCopy from "@/components/Global/Icons/IconCopy";
import copyRichTextToClipboard from "@/utils/FormHelpers/copyRichTextToClipboard";
import DiagnosisConsiderationWrapper from "@/components/Settings/Patient/DiagnosisConsiderationWrapper";
import { addIcons } from "ionicons";
import { checkmarkCircle, closeCircle, more } from "ionicons/icons";
import { mapGetters } from "vuex";

addIcons({
  "md-more": more.md,
  "md-checkmark-circle": checkmarkCircle.md,
  "md-close-circle": closeCircle.md
});

export default {
  name: "DiagnosisInsights",
  props: {
    currentPatient: Object,
    isCarded: {
      required: false,
      type: Boolean
    }
  },
  components: {
    IconCopy,
    DiagnosisConsiderationWrapper
  },

  data() {
    return {
      isSavingCounter: 0
    };
  },

  computed: {
    ...mapGetters({
      addressedDocRefs: "diagnosisConsiderations/getAddressedDocRefs"
    }),
    beneficiaryId() {
      return this.currentPatient.payload.beneficiary.id;
    }
  },

  methods: {
    /**
     * Map document references to their decoded text content.
     * @param documentReferences Document references you want to write out as a notes string
     */
    getDocReferencesText(documentReferences) {
      const combinedData = [];
      for (const doc of documentReferences) {
        if (doc?.content?.length) {
          const docs = doc?.content
            .map(c => c?.attachment?.data)
            .filter(c => c?.length)
            .map(atob);
          combinedData.push(docs.join(" "));
        }
      }
      return combinedData;
    },

    handleIsSavingCounterChanged(newVal) {
      this.isSavingCounter = newVal;
    },

    async copyDocumentation() {
      try {
        const copyStrings = this.getDocReferencesText(this.addressedDocRefs).filter(
          x => x?.length > 0
        );

        const htmlCopy = copyStrings.join("<br/><br/>");
        await copyRichTextToClipboard(htmlCopy);
        const toast = await this.$ionic.toastController.create({
          header: "Copied!",
          message: "Combined Documentation has been copied to your clipboard",
          duration: 4000,
          color: "primary",
          mode: "ios"
        });
        toast.present();
      } catch (err) {
        const toast = await this.$ionic.toastController.create({
          header: "Failed to copy",
          message: "There was a problem copying your documentation to the clipboard.",
          duration: 7000,
          position: "top"
        });
        toast.present();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.dx-container {
  width: 100%;
  container-type: inline-size !important;
}

h5 {
  margin-top: 30px;
}

.scrollable {
  max-height: 400px;
  overflow-y: auto;
}
</style>
